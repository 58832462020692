import React from "react";
import {
  mainOrange,
  ccbiffbamboomRegular,
  mainWhite,
  darkBlue,
  screen,
} from "./common/variables";
import styled from "styled-components";

const Wrapper = styled.div`
  perspective: 120px;
  display: inline-block;
  /* margin-bottom: 10px; */
  position: relative;
  span {
    display: inline-block;
    transform: rotateY(10deg);
    padding: 5px 15px;
    background-color: ${props => props.backgroundColor};
    font-family: ${ccbiffbamboomRegular};
    font-size: 1.3rem;
    color: ${props => props.color};
    max-width: 230px;
    @media ${screen.small} {
      font-size: 2.35rem;
      /* font-size: ${props => props.fontSize}; */
      max-width: inherit;
    }
    @media ${screen.medium} {
      font-size: 3rem;
      margin-bottom: 10px;
    }
  }
  
  /* .sub-slope {
    perspective: 85px;
    display: inline-block;
    margin-bottom: 10px;
    position: absolute;
    left: 0;
    top: -5px;
    display: none;

    span {
      background: ${mainWhite};
      color: ${darkBlue};
      display: inline-block;
      transform: rotateY(-12deg);
      padding: 13px 11px;
      font-family: ${ccbiffbamboomRegular};
      font-size: 1rem;
      text-align: center;
      line-height: 1.6;
       max-width: 260px;
       
       }
    } */
`;

const SlopedLabel = ({
  fontSize = "3rem",
  backgroundColor = mainOrange,
  color = "#fff",
  text = "xxx",
  className,
}) => {
  return (
    <Wrapper
      className={className ? `${className} sloped` : `sloped`}
      fontSize={fontSize}
      color={color}
      backgroundColor={backgroundColor}
    >
      <span>{text}</span>
      {/* <span className="sub-slope">
        <span>
          Martin Lysicrates Prize
          <br />
          2018 Winner!
        </span>
      </span> */}
    </Wrapper>
  );
};

export default SlopedLabel;

import React from "react";
import { Link } from "gatsby";
import Layout from "../layout";
import styled from "styled-components";
import imgWallpaper from "../images/home/wallpaper-background.jpg";
import imgPerson from "../images/404/martin-lysicrates-looking-lost.png";
import SEO from "../components/seo";
import {
  montserrat,
  darkBlue,
  screen,
  innerWidth,
  ccbiffbamboomRegular,
} from "../components/common/variables";
import SlopedLabel from "../components/SlopedLabel";

const Wrapper = styled.div`
  height: 700px;
  background-image: url(${imgWallpaper});
  background-repeat: repeat-y;
  background-position: center;
  position: relative;
  display: flex;
  font-family: ${montserrat};
  @media ${screen.medium} {
    height: calc(100vh - 170px);
  }
  @media ${screen.xlarge} {
    height: calc(90vh - 200px);
  }

  .bg-wrapper {
    background-image: url(${imgPerson});
    background-repeat: no-repeat;
    background-size: 330px;
    background-position: bottom right;
    max-width: ${innerWidth};
    width: 100%;
    margin: 0 auto;
    position: relative;
    padding: 0 50px;
    @media ${screen.small} {
      background-position: 550px bottom;
      background-size: calc(270px + 15vw);
    }

    @media ${screen.medium} {
      background-position: bottom right;
    }

    @media ${screen.large} {
      background-size: calc(400px + 15vw);
    }
    &__text {
      position: absolute;
      width: 100%;
      padding: 0 30px;
      top: 60px;
      transform: translate(-50%, 0);
      color: ${darkBlue};
      left: 50%;
      @media ${screen.small} {
        top: 50%;
        left: 8vw;
        transform: translate(0, -50%);
      }

      h6 {
        font-family: ${ccbiffbamboomRegular};
        font-size: 2.45rem;
        margin: 0 auto;
        text-align: center;
        @media ${screen.small} {
          font-size: 4rem;
          text-align: left;
          margin: 0;
          max-width: none;
        }
        @media ${screen.medium} {
          font-size: 5rem;
        }
      }

      p {
        font-size: 1.2rem;
        margin: 22px 0 32px 0;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        @media ${screen.small} {
          font-size: 1.4rem;
          text-align: left;
          max-width: 470px;
          margin: 15px 0;
        }
        @media ${screen.medium} {
          max-width: 536px;
          font-size: 1.55rem;
          margin: 25px 0 25px;
        }
      }

      a {
        display: flex;
        margin: 0 auto;
        width: 100%;
        justify-content: center;
        margin-left: -6px;
        @media ${screen.small} {
          margin-left: -14px;
          justify-content: flex-start;
        }

        span {
          transform: rotateY(-7deg);
          font-size: 1.88rem;
          @media ${screen.small} {
            font-size: 2.35rem;
          }
          @media ${screen.medium} {
            font-size: 3rem;
          }
        }
      }
    }
  }
`;
const NotFound = () => {
  return (
    <Layout>
      <SEO title="Page Not Found" />
      <Wrapper>
        <div className="bg-wrapper">
          <div className="bg-wrapper__text">
            <h6>
              YOU'RE LOOKING
              <br /> A BIT LOST...
            </h6>
            <p>
              This page can't be found, but let us help you navigate back to the
              real world.
            </p>
            <Link to="/">
              <SlopedLabel text="GO HOME" />
            </Link>
          </div>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default NotFound;
